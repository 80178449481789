<template>
  <div>
    <b-card
      :title="$i18n.messages.Productos"
      overlay
      :img-src="cardImage"
      img-alt="Card Image"
      text-variant="white"
      class="cardHeader border-0"
      v-if="windowWidth > 1000"
    >
    </b-card>
    <b-card :title="$i18n.messages.SelectProd">
      <b-row>
        <b-col md="4" sm="4" class="my-1">
          <b-form-group class="mb-0" label-size="lg" label-for="perPageSelect">
            <b-input-group size="md">
              <b-form-input
                id="filterInput"
                v-model="search"
                type="search"
                v-on:keydown.enter="listProducts()"
              />
              <b-input-group-append>
                <b-button variant="primary" @click="listProducts()">
                  {{ $i18n.messages.Buscar }}
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="12 my-2">
          <b-table
            striped
            responsive
            :stacked="stacked"
            :items="items"
            :fields="fields"
            @sort-changed="handleSortChange"
          >
            <template #cell(actions)="data">
              <router-link
                :to="{
                  name: 'product',
                  params: { id: data.item.idProducto },
                }"
              >
                <b-button class="btn-action" style="min-width: 100px;">
                  {{ $i18n.messages.DetailProduct }}
                </b-button>
                <!--    <b-button
              size="sm"
              variant="primary"
              class="btn-icon rounded-circle"
            >
              <feather-icon icon="EyeIcon" />
            </b-button> -->
              </router-link>
              <!--      <b-button
              variant="primary"
              class="btn-action"
              @click="openModalProduct(data.item.CodArt)"
              style="min-width: 100px;"
            >
              {{ $i18n.messages.Anyadir2 }}
            </b-button> -->
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
      <modal-add-product
        :product="productAdd"
        v-if="productAdd && Object.keys(productAdd).length"
      >
      </modal-add-product>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
} from "bootstrap-vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import ModalAddProduct from "@/views/ModalAddProduct.vue";
export default {
  components: {
    BCard,
    BTable,
    BPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    ModalAddProduct,
  },
  data() {
    return this.customData();
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    this.stacked = window.innerWidth <= 900;
    window.addEventListener("resize", () => {
      this.stacked = window.innerWidth <= 900;
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  computed: {
    ...mapGetters({
      items: "products/getListProducts",
      /* families: "families/getListFamilies",
      sizes: "sizes/getListSizes", */
      productAdd: "products/getProductAdd",
      totalItems: "products/getTotalListProducts",
      user: "auth/getUser",
      currentCustomer: "customers/getCurrentCustomer",
      cart: "carts/getCart",
    }),
  },
  created() {
    this.search = JSON.parse(localStorage.getItem("searchProd")) || ""; // value or null
    this.family = JSON.parse(localStorage.getItem("familyProd")) || ""; // value or null
    this.size = JSON.parse(localStorage.getItem("sizeProd")) || ""; // value or null
    this.currentPage = JSON.parse(localStorage.getItem("currentPageProd")) || 1; // value or null
    this.sortBy = JSON.parse(localStorage.getItem("sortByProd")) || ""; // value or null
    this.sortDesc = JSON.parse(localStorage.getItem("sortDescProd")) || "+"; // value or null

    var username = "";
    var idClient = "";

    console.log("client: ", this.currentCustomer);
    if (this.currentCustomer) {
      idClient = this.currentCustomer.Cliente;
    }
    console.log("IDUSER: ", this.user);
    username = this.user.usuario_id;

    if (this.user.rol_id === 2) {
      localStorage.setItem(
        "currentCustomer",
        JSON.stringify({
          Cliente: this.user.id_cliente,
          Comercial: "",
          Nombre: this.user.nombre,
        })
      );
      username = this.user.usuario_id;
      idClient = "ADMIN";
    }

    /*  this.searchFamilies(null);
    this.searchSizes(null); */

    this.setLoading(true);
    this.getCartOrCreate({
      username: username.toString(),
      idClient: idClient.toString(),
    }).then(() => {
      this.setLoading(false);
    });
    this.listProducts();
  },
  watch: {
    windowWidth(newWidth, oldWidth) {
      this.txt = `it changed to ${newWidth} from ${oldWidth}`;
    },
    currentPage() {
      this.listProducts();
    },
    perPage() {
      this.listProducts();
    },
    sortBy() {
      this.listProducts();
    },
    sortDesc() {
      this.listProducts();
    },
    family() {
      console.log(this.family);
      this.searchSizes(this.family);
      this.listProducts();
    },
    size() {
      console.log(this.size);
      this.searchFamilies(this.size);
      this.listProducts();
    },
  },
  methods: {
    ...mapActions({
      searchProducts: "products/searchProducts",
      searchSizes: "sizes/searchSizes",
      searchFamilies: "families/searchFamilies",
      getProductAdd: "products/getProductAdd",
      getCartOrCreate: "carts/getCartOrCreate",
    }),
    ...mapMutations({
      setShowModalAdd: "products/setShowModalAdd",
      setLoading: "app/setLoading",
      recoverCurrentCustomer: "customers/recoverCurrentCustomer",
      setCurrentCustomer: "customers/setCurrentCustomer",
    }),
    handleSortChange(context) {
      // this is called when b-table with ref "mytable" hears the 'sort-changed' event
      // that it has emitted

      // sorting logic goes here
      console.log(context);
    },
    customData() {
      return {
        stacked: false,
        pageOptions: [10, 15, 20],
        cardImage: require("@/assets/images/pages/image-header-productos.svg"),
        sortBy: "",
        sortDesc: "+",
        sortOptions: [
          { text: "Stock", value: "stock" },
          { text: "Date", value: "date" },
        ],
        sortOptionsAgent: [{ text: "Date", value: "date" }],
        orderOptions: [
          { text: "Desc", value: "+" },
          { text: "Asc", value: "-" },
        ],
        currentPage: 1,
        perPage: 12,
        search: "",
        sort: "",
        family: "",
        size: "",
        windowWidth: window.innerWidth,
        fields: [
          {
            key: "Nombre",
            label: this.$i18n.messages.Productos,
            tdClass: "textcell",
          },
          {
            key: "idProducto",
            label: this.$i18n.messages.Code,
            tdClass: "textcell",
          },
          {
            key: "Actions",
            label: "",
            class: "btnActionRWD",
          },
        ],
      };
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    listProducts() {
      const {
        search,
        currentPage,
        perPage,
        sortBy,
        sortDesc,
        family,
        size,
      } = this;

      localStorage.setItem("searchProd", JSON.stringify(search));
      localStorage.setItem("familyProd", JSON.stringify(family));
      localStorage.setItem("sizeProd", JSON.stringify(size));
      localStorage.setItem("currentPageProd", JSON.stringify(currentPage));
      localStorage.setItem("sortByProd", JSON.stringify(sortBy));
      localStorage.setItem("sortDescProd", JSON.stringify(sortDesc));

      const limit = 12;
      const offset = currentPage - 1;

      if (search != "") {
        console.log("ENTRA");
        // limit = this.totalItems;
        this.currentPage = 1;
        // this.totalItems = 0;
        this.perPage = this.totalItems;
      } else {
        this.perPage = limit;
      }
      /* let sort = "";
      if (sortBy) {
        sort = sortDesc + sortBy;
      } */
      this.setLoading(true);
      this.searchProducts({
        search,
        limit,
        offset,
        /*  sort,
        family,
        size,
        currentPage, */
      }).then(() => {
        this.setLoading(false);
      });
    },
    resetFilters() {
      Object.assign(this.$data, this.customData());
    },
    async openModalProduct(getProduct) {
      if (this.currentCustomer === "" || this.currentCustomer === null) {
        this.recoverCurrentCustomer();
        /*  this.modalShow = true; */
      } else {
        this.setLoading(true);
        await this.getProductAdd(getProduct);
        await this.setShowModalAdd(true);
        this.setLoading(false);
      }
    },
  },
};
</script>
